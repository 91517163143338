var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}(window, function () {
  return function (e) {
    var t = {};

    function n(r) {
      if (t[r]) return t[r].exports;
      var o = t[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(o.exports, o, o.exports, n), o.l = !0, o.exports;
    }

    return n.m = e, n.c = t, n.d = function (e, t, r) {
      n.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: r
      });
    }, n.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, n.t = function (e, t) {
      if (1 & t && (e = n(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var r = Object.create(null);
      if (n.r(r), Object.defineProperty(r, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var o in e) n.d(r, o, function (t) {
        return e[t];
      }.bind(null, o));
      return r;
    }, n.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return n.d(t, "a", t), t;
    }, n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, n.p = "/", n(n.s = 4);
  }([function (e, t, n) {
    var r = n(1),
        o = n(2);
    "string" == typeof (o = o.__esModule ? o.default : o) && (o = [[e.i, o, ""]]);
    var a = {
      insert: "head",
      singleton: !1
    };
    r(o, a);
    e.exports = o.locals || {};
  }, function (e, t, n) {
    "use strict";

    var r,
        o = function () {
      return void 0 === r && (r = Boolean(window && document && document.all && !window.atob)), r;
    },
        a = function () {
      var e = {};
      return function (t) {
        if (void 0 === e[t]) {
          var n = document.querySelector(t);
          if (window.HTMLIFrameElement && n instanceof window.HTMLIFrameElement) try {
            n = n.contentDocument.head;
          } catch (e) {
            n = null;
          }
          e[t] = n;
        }

        return e[t];
      };
    }(),
        i = [];

    function c(e) {
      for (var t = -1, n = 0; n < i.length; n++) if (i[n].identifier === e) {
        t = n;
        break;
      }

      return t;
    }

    function s(e, t) {
      for (var n = {}, r = [], o = 0; o < e.length; o++) {
        var a = e[o],
            s = t.base ? a[0] + t.base : a[0],
            u = n[s] || 0,
            l = "".concat(s, " ").concat(u);
        n[s] = u + 1;
        var d = c(l),
            f = {
          css: a[1],
          media: a[2],
          sourceMap: a[3]
        };
        -1 !== d ? (i[d].references++, i[d].updater(f)) : i.push({
          identifier: l,
          updater: b(f, t),
          references: 1
        }), r.push(l);
      }

      return r;
    }

    function u(e) {
      var t = document.createElement("style"),
          r = e.attributes || {};

      if (void 0 === r.nonce) {
        var o = n.nc;
        o && (r.nonce = o);
      }

      if (Object.keys(r).forEach(function (e) {
        t.setAttribute(e, r[e]);
      }), "function" == typeof e.insert) e.insert(t);else {
        var i = a(e.insert || "head");
        if (!i) throw new Error("Couldn't find a style target. This probably means that the value for the 'insert' parameter is invalid.");
        i.appendChild(t);
      }
      return t;
    }

    var l,
        d = (l = [], function (e, t) {
      return l[e] = t, l.filter(Boolean).join("\n");
    });

    function f(e, t, n, r) {
      var o = n ? "" : r.media ? "@media ".concat(r.media, " {").concat(r.css, "}") : r.css;
      if (e.styleSheet) e.styleSheet.cssText = d(t, o);else {
        var a = document.createTextNode(o),
            i = e.childNodes;
        i[t] && e.removeChild(i[t]), i.length ? e.insertBefore(a, i[t]) : e.appendChild(a);
      }
    }

    function p(e, t, n) {
      var r = n.css,
          o = n.media,
          a = n.sourceMap;
      if (o ? e.setAttribute("media", o) : e.removeAttribute("media"), a && btoa && (r += "\n/*# sourceMappingURL=data:application/json;base64,".concat(btoa(unescape(encodeURIComponent(JSON.stringify(a)))), " */")), e.styleSheet) e.styleSheet.cssText = r;else {
        for (; e.firstChild;) e.removeChild(e.firstChild);

        e.appendChild(document.createTextNode(r));
      }
    }

    var h = null,
        v = 0;

    function b(e, t) {
      var n, r, o;

      if (t.singleton) {
        var a = v++;
        n = h || (h = u(t)), r = f.bind(null, n, a, !1), o = f.bind(null, n, a, !0);
      } else n = u(t), r = p.bind(null, n, t), o = function () {
        !function (e) {
          if (null === e.parentNode) return !1;
          e.parentNode.removeChild(e);
        }(n);
      };

      return r(e), function (t) {
        if (t) {
          if (t.css === e.css && t.media === e.media && t.sourceMap === e.sourceMap) return;
          r(e = t);
        } else o();
      };
    }

    e.exports = function (e, t) {
      (t = t || {}).singleton || "boolean" == typeof t.singleton || (t.singleton = o());
      var n = s(e = e || [], t);
      return function (e) {
        if (e = e || [], "[object Array]" === Object.prototype.toString.call(e)) {
          for (var r = 0; r < n.length; r++) {
            var o = c(n[r]);
            i[o].references--;
          }

          for (var a = s(e, t), u = 0; u < n.length; u++) {
            var l = c(n[u]);
            0 === i[l].references && (i[l].updater(), i.splice(l, 1));
          }

          n = a;
        }
      };
    };
  }, function (e, t, n) {
    (t = n(3)(!1)).push([e.i, ".ce-code__textarea {\n    min-height: 200px;\n    font-family: Menlo, Monaco, Consolas, Courier New, monospace;\n    color: #41314e;\n    line-height: 1.6em;\n    font-size: 12px;\n    background: #f8f7fa;\n    border: 1px solid #f1f1f4;\n    box-shadow: none;\n    white-space: pre;\n    word-wrap: normal;\n    overflow-x: auto;\n    resize: vertical;\n}\n", ""]), e.exports = t;
  }, function (e, t, n) {
    "use strict";

    e.exports = function (e) {
      var t = [];
      return t.toString = function () {
        return this.map(function (t) {
          var n = function (e, t) {
            var n = e[1] || "",
                r = e[3];
            if (!r) return n;

            if (t && "function" == typeof btoa) {
              var o = (i = r, c = btoa(unescape(encodeURIComponent(JSON.stringify(i)))), s = "sourceMappingURL=data:application/json;charset=utf-8;base64,".concat(c), "/*# ".concat(s, " */")),
                  a = r.sources.map(function (e) {
                return "/*# sourceURL=".concat(r.sourceRoot || "").concat(e, " */");
              });
              return [n].concat(a).concat([o]).join("\n");
            }

            var i, c, s;
            return [n].join("\n");
          }(t, e);

          return t[2] ? "@media ".concat(t[2], " {").concat(n, "}") : n;
        }).join("");
      }, t.i = function (e, n, r) {
        "string" == typeof e && (e = [[null, e, ""]]);
        var o = {};
        if (r) for (var a = 0; a < (this || _global).length; a++) {
          var i = (this || _global)[a][0];
          null != i && (o[i] = !0);
        }

        for (var c = 0; c < e.length; c++) {
          var s = [].concat(e[c]);
          r && o[s[0]] || (n && (s[2] ? s[2] = "".concat(n, " and ").concat(s[2]) : s[2] = n), t.push(s));
        }
      }, t;
    };
  }, function (e, t, n) {
    "use strict";

    n.r(t), n.d(t, "default", function () {
      return a;
    });
    n(0);

    function r(e, t) {
      for (var n = 0; n < t.length; n++) {
        var r = t[n];
        r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
      }
    }

    function o(e, t, n) {
      return t && r(e.prototype, t), n && r(e, n), e;
    }
    /**
     * CodeTool for Editor.js
     *
     * @author CodeX (team@ifmo.su)
     * @copyright CodeX 2018
     * @license MIT
     * @version 2.0.0
     */


    var a = function () {
      function e(t) {
        var n = t.data,
            r = t.config,
            o = t.api,
            a = t.readOnly;
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, e), (this || _global).api = o, (this || _global).readOnly = a, (this || _global).placeholder = (this || _global).api.i18n.t(r.placeholder || e.DEFAULT_PLACEHOLDER), (this || _global).CSS = {
          baseClass: (this || _global).api.styles.block,
          input: (this || _global).api.styles.input,
          wrapper: "ce-code",
          textarea: "ce-code__textarea"
        }, (this || _global).nodes = {
          holder: null,
          textarea: null
        }, (this || _global).data = {
          code: n.code || ""
        }, (this || _global).nodes.holder = this.drawView();
      }

      return o(e, null, [{
        key: "isReadOnlySupported",
        get: function () {
          return !0;
        }
      }, {
        key: "enableLineBreaks",
        get: function () {
          return !0;
        }
      }]), o(e, [{
        key: "drawView",
        value: function () {
          var e = this || _global,
              t = document.createElement("div"),
              n = document.createElement("textarea");
          return t.classList.add((this || _global).CSS.baseClass, (this || _global).CSS.wrapper), n.classList.add((this || _global).CSS.textarea, (this || _global).CSS.input), n.textContent = (this || _global).data.code, n.placeholder = (this || _global).placeholder, (this || _global).readOnly && (n.disabled = !0), t.appendChild(n), n.addEventListener("keydown", function (t) {
            switch (t.code) {
              case "Tab":
                e.tabHandler(t);
            }
          }), (this || _global).nodes.textarea = n, t;
        }
      }, {
        key: "render",
        value: function () {
          return (this || _global).nodes.holder;
        }
      }, {
        key: "save",
        value: function (e) {
          return {
            code: e.querySelector("textarea").value
          };
        }
      }, {
        key: "onPaste",
        value: function (e) {
          var t = e.detail.data;
          (this || _global).data = {
            code: t.textContent
          };
        }
      }, {
        key: "tabHandler",
        value: function (e) {
          e.stopPropagation(), e.preventDefault();
          var t,
              n = e.target,
              r = e.shiftKey,
              o = n.selectionStart,
              a = n.value;

          if (r) {
            var i = function (e, t) {
              for (var n = ""; "\n" !== n && t > 0;) t -= 1, n = e.substr(t, 1);

              return "\n" === n && (t += 1), t;
            }(a, o);

            if ("  " !== a.substr(i, "  ".length)) return;
            n.value = a.substring(0, i) + a.substring(i + "  ".length), t = o - "  ".length;
          } else t = o + "  ".length, n.value = a.substring(0, o) + "  " + a.substring(o);

          n.setSelectionRange(t, t);
        }
      }, {
        key: "data",
        get: function () {
          return (this || _global)._data;
        },
        set: function (e) {
          (this || _global)._data = e, (this || _global).nodes.textarea && ((this || _global).nodes.textarea.textContent = e.code);
        }
      }], [{
        key: "toolbox",
        get: function () {
          return {
            icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" viewBox=\"0 0 24 24\"><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M9 8L5 12L9 16\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M15 8L19 12L15 16\"/></svg>",
            title: "Code"
          };
        }
      }, {
        key: "DEFAULT_PLACEHOLDER",
        get: function () {
          return "Enter a code";
        }
      }, {
        key: "pasteConfig",
        get: function () {
          return {
            tags: ["pre"]
          };
        }
      }, {
        key: "sanitize",
        get: function () {
          return {
            code: !0
          };
        }
      }]), e;
    }();
  }]).default;
});
export default exports;
export const CodeTool = exports.CodeTool;